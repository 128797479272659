import Image from "next/image";
import Link from "next/link";

import CourseDetails from "../../data/course-details/courseData.json";

const Card = ({ start, end, col, mt, isDesc, isUser }) => {
  return (
    <>
      {CourseDetails &&
        CourseDetails.courseDetails.slice(start, end).map((data, index) => (
          <div
            className={`${col} ${mt}`}
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
            key={index}
          >
            <div className="rbt-card variation-01 rbt-hover">
              <div className="rbt-card-img">
                <a href={`/course-details/${data.courseSlug}`}>
                  <Image
                    src={data.courseImg}
                    width={355}
                    height={244}
                    alt="Card image"
                  />
                  {/* {data.offPrice > 0 ? (
                    <div className="rbt-badge-3 bg-white">
                      <span>-{data.offPrice}%</span>
                      <span>Off</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                </a>
              </div>
              <div className="rbt-card-body">
                {/* <div className="rbt-card-top">
                  <div className="rbt-review">
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <span className="rating-count">
                      ({data.review} Reviews)
                    </span>
                  </div>
                  <div className="rbt-bookmark-btn">
                    <a className="rbt-round-btn" title="Bookmark" href="#">
                      <i className="feather-bookmark"></i>
                    </a>
                  </div>
                </div> */}

                <h4 className="rbt-card-title">
                  <a href={`/course-details/${data.courseSlug}`}>
                    {data.courseTitle}
                  </a>
                </h4>

                <ul className="rbt-meta">
                  <li>
                    <i className="feather-book"></i>
                    {data.lesson}+ Ders Saati
                  </li>
                  <li>
                    <i className="feather-users"></i>
                    {data.student} Kontenjan
                  </li>
                </ul>
                {isDesc ? (
                  <p className="rbt-card-text clamp-6-lines">{data.desc}</p>
                ) : (
                  ""
                )}
                {/* {isUser ? (
                  <div className="rbt-author-meta mb--10">
                    <div className="rbt-avater">
                      <a href={`/profile/${data.courseSlug}`}>
                        <Image
                          src={data.userImg}
                          width={33}
                          height={33}
                          alt="Sophia Jaymes"
                        />
                      </a>
                    </div>
                    <div className="rbt-author-info">
                      By{" "}
                      <a href={`/profile/${data.courseSlug}`}>{data.userName}</a>{" "}
                      In <a href="#">{data.userCategory}</a>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="rbt-card-bottom">
                  <div className="rbt-price">
                    <span className="current-price">{data.price}TL</span>
                    <span className="off-price">{data.offPrice}TL</span>
                  </div>
                  {data.button ? (
                    <a
                      className="rbt-btn-link left-icon"
                      href={`/course-details/${data.courseSlug}`}
                    >
                      <i className="feather-shopping-cart"></i> Add To Cart
                    </a>
                  ) : (
                    <a
                      className="rbt-btn-link"
                      href={`/course-details/${data.courseSlug}`}
                    >
                      Detaylı Bilgi<i className="feather-arrow-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Card;
