import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Pagination, Autoplay } from 'swiper/modules';

import MainDemoData from '../../../data/course-details/courseData.json';

const HomeCourses = ({ start, end }) => {
  return (
    <>
      <Swiper
        className="swiper-wrapper"
        effect={'cards'}
        modules={[EffectCards, Pagination, Autoplay]}
        grabCursor={true} // sağa sola kaydırma için
        touchRatio={1} // sağa sola kaydırma için
        pagination={{
          el: '.rbt-swiper-pagination',
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {MainDemoData &&
          MainDemoData.courseDetails.slice(start, end).map((data, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="rbt-card variation-01 rbt-hover">
                <div className="rbt-card-img">
                  <a
                    href={`${
                      data.courseSlug.includes('react')
                        ? 'https://portal.bilgenakademi.com/egitimler/basvuru/react-ile-front-end-gelistirme-2411'
                        : 'https://portal.bilgenakademi.com/egitimler/basvuru/nodejs-ile-back-end-gelistirme-2411'
                    }`}
                  >
                    <Image
                      src={data.courseImg}
                      width={710}
                      height={488}
                      alt="Card image"
                    />
                    {/* <div className="rbt-badge-3 bg-white">
                      <span>-{data.discount}%</span>
                      <span>Off</span>
                    </div> */}
                  </a>
                </div>
                <div className="rbt-card-body">
                  <ul className="rbt-meta">
                    <li>
                      <i className="feather-book"></i>
                      {data.lesson}+ Ders Saati
                    </li>
                    <li>
                      <i className="feather-users"></i>
                      {data.student} Kontenjan
                    </li>
                  </ul>
                  <h4 className="rbt-card-title">
                    <a
                      href={`${
                        data.courseSlug.includes('react')
                          ? 'https://portal.bilgenakademi.com/egitimler/basvuru/react-ile-front-end-gelistirme-2411'
                          : 'https://portal.bilgenakademi.com/egitimler/basvuru/nodejs-ile-back-end-gelistirme-2411'
                      }`}
                    >
                      {data.courseTitle}
                    </a>
                  </h4>
                  <p className="rbt-card-text">{data.desc.substring(0, 100)}</p>
                  {/* <div className="rbt-review">
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <span className="rating-count">
                      ({data.review} Reviews)
                    </span>
                  </div> */}
                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span className="current-price">{data.price}TL</span>
                      <span className="off-price">{data.offPrice}TL</span>
                    </div>
                    <a
                      className="rbt-btn-link"
                      href={`${
                        data.courseSlug.includes('react')
                          ? 'https://portal.bilgenakademi.com/egitimler/basvuru/react-ile-front-end-gelistirme-2411'
                          : 'https://portal.bilgenakademi.com/egitimler/basvuru/nodejs-ile-back-end-gelistirme-2411'
                      }`}
                    >
                      Detaylı Bilgi
                      <i className="feather-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        <div className="rbt-swiper-pagination"></div>
      </Swiper>
    </>
  );
};

export default HomeCourses;
